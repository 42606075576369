<template>
  <base-section id="404" space="0">
    <section id="header-home" class="white--text">
      <h1 class="text-h2 text-sm-h1 text-uppercase">{{ $t("titleHome") }}</h1>
      <h3 class="text-h4 text-sm-h3">{{ $t("subtitleHome") }}</h3>
      <base-btn color="success" :to="{ name: 'Menu' }">
        {{ $t("btnHome") }}
      </base-btn>
    </section>
    <v-container class="fill-height px-4 pt-12 pb-6">
      <p class="text-home text-center">{{ $t("textHome") }}</p>
    </v-container>
  </base-section>
</template>

<script>
export default {
  name: "WelcomeSection",

  provide: {
    heading: { align: "center" },
  },
  data() {
    return {};
  },
};
</script>
<style lang="sass">
#header-home
  background: url("../../../assets/img/background-about-us.webp") center center / cover no-repeat
  min-height: 320px
  height: 50vh
  max-height: 500px
  display: flex
  flex-direction: column
  justify-content: center
  align-items: center
  gap: 15px
  text-align: center

.text-home
  font-size: 1.2rem
</style>
